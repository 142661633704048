export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Country/Region',
      field: 'country.name',
      sort: 'asc',
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'NMO',
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.data.head,
      cellRenderer: ProfileLink,
    },
    {
      headerName: 'IFAC correspondent',
      field: 'ifac.name',
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.data.ifac,
      cellRenderer: ProfileLink,
    },
  ];
}

export function handleAffiliates(affiliates) {
  return affiliates;
}
